import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`From 0 to Docker`}</h1>
    <p>{`Recently, I took some time to learn about Docker.`}</p>
    <h2><HashLink id="terminology" to="/docker#terminology" mdxType="HashLink">{`Terminology`}</HashLink></h2>
    <p>{`A single docker `}<strong parentName="p">{`image`}</strong>{` can be reused to provision containers. An image can be thought about like a DVD. You can use it on multiple devices and the same movie plays.`}</p>
    <p>{`A `}<strong parentName="p">{`dockerfile `}</strong>{`is a text document with the sequential instructions necessary to build a docker image.`}</p>
    <p>{`An application along with its dependencies is packaged in a `}<strong parentName="p">{`container`}</strong>{`. A container is a running, paused or stopped instance of an image. Although you can run more than one process with a container, they are designed to run a single process. Containers are also designed to be ephemeral. The data stored on containers is inherently ephemeral, as well.`}</p>
    <p>{`A `}<strong parentName="p">{`registry `}</strong>{`is a repository for images. The most popular example of a registry is Docker Hub. I think of Docker Hub like I think of the NPM registry. Instead of NPM packages you can pull container images.`}</p>
    <p>{`A `}<strong parentName="p">{`tag `}</strong>{`allows you to add a unique version identifier to an image name.`}</p>
    <h2><HashLink id="install" to="/docker#install" mdxType="HashLink">{`Install`}</HashLink></h2>
    <p>{`While experimenting with Docker I’ve been using WSL2 on my Windows 10 machine to run Ubuntu 20.04. These are the `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/ubuntu/"
      }}>{`installation steps`}</a>{` I’ve followed to get Docker up and running.`}</p>
    <p>{`Make sure to start the docker service before running the sample image.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ sudo service docker start

$ sudo docker run hello-world

`}</code></pre>
    <p>{`This runs one instance (container) of the `}<inlineCode parentName="p">{`hello-world`}</inlineCode>{` image.`}</p>
    <p>{`I followed the `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/linux-postinstall/#manage-docker-as-a-non-root-user"
      }}>{`Linux post install steps`}</a>{` to avoid having to run `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` every time I run a docker command.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ sudo groupadd docker

$ sudo usermod -aG docker $USER

`}</code></pre>
    <h2><HashLink id="cmd-usage" to="/docker#cmd-usage" mdxType="HashLink">{`Command-line usage`}</HashLink></h2>
    <h3><HashLink id="containers" to="/docker#containers" mdxType="HashLink">{`Container management`}</HashLink></h3>
    <p>{`You can use the container’s name in many (maybe all?) of these commands. For the sake of brevity I’ll use `}<inlineCode parentName="p">{`container`}</inlineCode>{` when I mean `}<inlineCode parentName="p">{`container_id`}</inlineCode>{` or `}<inlineCode parentName="p">{`container_name`}</inlineCode>{`.`}</p>
    <p>{`To provision a container use `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/reference/run/"
      }}>{`the `}<inlineCode parentName="a">{`docker run`}</inlineCode>{` command`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker run image

`}</code></pre>
    <p>{`To name your container instead of using the randomly generated name:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker run --name cool_name image

`}</code></pre>
    <p>{`Note: You can’t have 2 containers deployed with the same name.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`-e`}</inlineCode>{` option to add environment variables.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker run -e MY_VAR=myValue image

`}</code></pre>
    <p>{`Containers are isolated so you need to explicitly expose them to the outside world. To do this using the `}<inlineCode parentName="p">{`docker run`}</inlineCode>{` command we can leverage the `}<inlineCode parentName="p">{`--expose`}</inlineCode>{` option. Also you can map or publish the exposed port to a port on your host system with the `}<inlineCode parentName="p">{`-P`}</inlineCode>{` option or `}<inlineCode parentName="p">{`-p container_port:host_port`}</inlineCode>{`. I had to use -P in order to properly connect to my Ubuntu localhost from my Windows browser when using nginx.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker run -P nginx

`}</code></pre>
    <p>{`See which containers are running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker ps

`}</code></pre>
    <p>{`See all containers (running, paused and stopped):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker ps -a

`}</code></pre>
    <p>{`You can use the smallest number of characters to uniquely identify a container. For example, if you’re only running one container and it has an ID of `}<inlineCode parentName="p">{`d64c42228850`}</inlineCode>{` you can just use `}<inlineCode parentName="p">{`d`}</inlineCode>{` to identify the container when running docker commands.`}</p>
    <p>{`Get detailed info about a container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker inspect container

`}</code></pre>
    <p>{`Some other useful commands for managing containers are listed below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker pause container

$ docker unpause container

$ docker stop container

`}</code></pre>
    <p>{`Similar to `}<inlineCode parentName="p">{`docker stop`}</inlineCode>{`, the `}<inlineCode parentName="p">{`docker kill`}</inlineCode>{` command will stop the container but it will kill the process instead of gracefully shutting it down.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker kill container

`}</code></pre>
    <p>{`Stopped containers remain in the system and take up space. To remove a stopped container (it must be stopped before you can remove):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker rm container

`}</code></pre>
    <p>{`To automatically remove the container when it exits:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker run --rm image

`}</code></pre>
    <p>{`To stop all running containers in bash:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker stop $(docker ps -q)

`}</code></pre>
    <p>{`To remove all exited containers in bash:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker rm $(docker ps -q -f status=exited)

`}</code></pre>
    <p>{`If all containers are stopped you can remove them all with one command.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker rm $(docker ps -aq)

`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`exec`}</inlineCode>{` command executes a command against a running container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker exec container ls /

`}</code></pre>
    <p>{`To get into an interactive bash shell inside a container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker exec -it container /bin/bash

`}</code></pre>
    <p>{`This is nice for running commands against a container to later be added to a dockerfile once tested out or for debugging a running container. We can use the `}<inlineCode parentName="p">{`exit`}</inlineCode>{` command or CMD + d to disconnect.`}</p>
    <p>{`To inspect container logs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker logs container_id

`}</code></pre>
    <p>{`We can also add `}<inlineCode parentName="p">{`-f`}</inlineCode>{` to tail logs.`}</p>
    <h3><HashLink id="images" to="/docker#images" mdxType="HashLink">{`Image management`}</HashLink></h3>
    <p>{`To list local images:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker images

`}</code></pre>
    <p>{`Get new images:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker pull image_name

`}</code></pre>
    <p>{`To remove docker images:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker rmi image_name

`}</code></pre>
    <p>{`Note: to remove an image all associated containers running, paused or stopped must first be removed.`}</p>
    <p>{`To tag images:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker tag image new_image

`}</code></pre>
    <p>{`To push images to a repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker login

$ docker push image

`}</code></pre>
    <h2><HashLink id="persist-data" to="/docker#persist-data" mdxType="HashLink">{`Persisting Data`}</HashLink></h2>
    <h3><HashLink id="volume-map" to="/docker#volume-map" mdxType="HashLink">{`Volume Mapping`}</HashLink></h3>
    <p>{`We can map a container directory to a directory on the host machine. This will allow data saved to the specified directory in the container to remain in the directory on the host machine when the container is removed. Likewise, changes made on the host machine will be reflected in the container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker run -itd -v ~/local_relative_dir:/container_dir ubuntu

`}</code></pre>
    <p>{`Note: I had to use the absolute path to get this to work properly with WSL 2.`}</p>
    <h3><HashLink id="volumes" to="/docker#volumes" mdxType="HashLink">{`Docker Volumes`}</HashLink></h3>
    <p>{`Another option is to use volume mounts that are managed by Docker. `}<a parentName="p" {...{
        "href": "https://docs.docker.com/storage/volumes/"
      }}>{`Docker Volumes`}</a>{` are the preferred way to persist data. You can mount more than one volume inside a container and you can mount any number of containers to a single volume.`}</p>
    <p>{`To create a volume:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker volume create my_volume

`}</code></pre>
    <p>{`To list volumes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker volume ls

`}</code></pre>
    <p>{`To map a volume:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker run -itd -v my_volume:/container_dir ubuntu

`}</code></pre>
    <p>{`To find the path to the volume on your local host machine:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker volume inspect my_volume

`}</code></pre>
    <p>{`In the resulting JSON, the value of “Mountpoint” is the path to the volume. This path can be configured.`}</p>
    <h2><HashLink id="dockerfiles" to="/docker#dockerfiles" mdxType="HashLink">{`Dockerfiles`}</HashLink></h2>
    <p>{`A Dockerfile is a text document that contains all the commands a user could call on the command line to assemble an image.`}</p>
    <p>{`There are several Dockerfile instruction options.`}</p>
    <p><inlineCode parentName="p">{`FROM`}</inlineCode>{` - Specify the image to base your image off of. Multiply FROM values designate a multi-stage docker build.`}</p>
    <p><inlineCode parentName="p">{`RUN`}</inlineCode>{` - Run a linux command.`}</p>
    <p><inlineCode parentName="p">{`ADD`}</inlineCode>{` - Copy files into the container from the host machine or from a remote URL.`}</p>
    <p><inlineCode parentName="p">{`ENV`}</inlineCode>{` - Set an environment variable.`}</p>
    <p><inlineCode parentName="p">{`EXPOSE`}</inlineCode>{` - Expose a port.`}</p>
    <p><inlineCode parentName="p">{`WORKDIR`}</inlineCode>{` - Set the working directory.`}</p>
    <p><inlineCode parentName="p">{`USER`}</inlineCode>{` - Set the user or group to use when running the container.`}</p>
    <p><inlineCode parentName="p">{`VOLUME`}</inlineCode>{` - Create a mount point with the specified name and mark it as holding externally mounted volumes from the host or other containers.`}</p>
    <p><inlineCode parentName="p">{`ENTRYPOINT`}</inlineCode>{` - Set how to enter, or, how to start the application.`}</p>
    <p><inlineCode parentName="p">{`CMD`}</inlineCode></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Set default command for running a container.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Also can be used to postfix the ENTRYPOINT command if it exits.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you list more than one CMD then only the last CMD will take effect.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`An inline CMD will override the default in the Dockerfile (`}<inlineCode parentName="p">{`docker run image [CMD]`}</inlineCode>{`)`}</p>
      </li>
    </ul>
    <p><inlineCode parentName="p">{`LABEL`}</inlineCode>{` - Add metadata.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/reference/builder/"
      }}>{`Dockerfile reference documentation`}</a>{` for a full list of all the options.`}</p>
    <p>{`Once we have our Dockerfile we can use `}<inlineCode parentName="p">{`docker build`}</inlineCode>{` to create an automated build that executes several command-line instructions from the Dockerfile in succession. If you run the build command more than once it will only build the delta between build runs. However, you can utilize the `}<inlineCode parentName="p">{`--no-cache`}</inlineCode>{` flag to build everything instead.`}</p>
    <p>{`Here’s a simple example of building an image using a Dockerfile.`}</p>
    <p>{`Script file (hello_world.sh)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`#!/bin/bash

echo "Hey! We did some docker stuff!"
`}</code></pre>
    <p>{`Dockerfile`}</p>
    <pre><code parentName="pre" {...{}}>{`FROM ubuntu

RUN apt update -y && apt upgrade -y

WORKDIR ~/script

COPY hello-world.sh .
RUN chmod +x hello-world.sh

CMD './hello-world.sh'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker build -t hello_world .

$ docker run hello_world

`}</code></pre>
    <h3><HashLink id="performance" to="/docker#performance" mdxType="HashLink">{`Performance`}</HashLink></h3>
    <p>{`Multi-stage builds are useful for ending up with smaller image sizes. Here’s an excerpt from the `}<a parentName="p" {...{
        "href": "https://docs.docker.com/develop/develop-images/multistage-build/"
      }}>{`Docker docs for multi-stage builds`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`One of the most challenging things about building images is keeping the image size down. Each instruction in the Dockerfile adds a layer to the image, and you need to remember to clean up any artifacts you don’t need before moving on to the next layer. To write a really efficient Dockerfile, you have traditionally needed to employ shell tricks and other logic to keep the layers as small as possible and to ensure that each layer has the artifacts it needs from the previous layer and nothing else.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`It was actually very common to have one Dockerfile to use for development (which contained everything needed to build your application), and a slimmed-down one to use for production, which only contained your application and exactly what was needed to run it. This has been referred to as the “builder pattern”. Maintaining two Dockerfiles is not ideal.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`With multi-stage builds, you use multiple FROM statements in your Dockerfile. Each FROM instruction can use a different base, and each of them begins a new stage of the build. You can selectively copy artifacts from one stage to another, leaving behind everything you don’t want in the final image. To show how this works, let’s adapt the Dockerfile from the previous section to use multi-stage builds.`}</p>
    </blockquote>
    <p>{`Here’s an example of cutting down image size using a multi-stage build.`}</p>
    <p>{`373MB`}</p>
    <pre><code parentName="pre" {...{}}>{`FROM golang:alpine
WORKDIR /fizzbuzz
COPY . .
RUN go build -v -o FizzBuzzApp
CMD ["./FizzBuzzApp"]
`}</code></pre>
    <p>{`To`}</p>
    <p>{`7.64MB`}</p>
    <pre><code parentName="pre" {...{}}>{`FROM golang:alpine AS build-step
WORKDIR /fizzbuzz
COPY . .
RUN go build -v -o FizzBuzzApp

FROM alpine
WORKDIR /app
COPY --from=build-step /fizzbuzz/FizzBuzzApp .
CMD ["./FizzBuzzApp"]
`}</code></pre>
    <p>{`In addition to multi-stage builds, we can leverage layers to boost performance. Layers are intermediate changes in an image. Each instruction in a dockerfile creates a new layer. Layers can be cached and reused for performance benefit.`}</p>
    <h2><HashLink id="networking" to="/docker#networking" mdxType="HashLink">{`Networking`}</HashLink></h2>
    <h3><HashLink id="network-management" to="/docker#network-management" mdxType="HashLink">{`Network management`}</HashLink></h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker network ls

$ docker network create my_network

$ docker network inspect my_network

$ docker network rm my_network

`}</code></pre>
    <p>{`You can’t remove a network with running containers associated with it.`}</p>
    <p>{`Containers cannot speak across networks only within them. To run a container within a specified network.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker run -d --network=my_network nginx

`}</code></pre>
    <p>{`Note: Since the container ID is also the host name of the container you can use it to ping, etc.`}</p>
    <h3><HashLink id="network-types" to="/docker#network-types" mdxType="HashLink">{`Network types`}</HashLink></h3>
    <p>{`Containers can communicate via IP addresses or hostnames within a network (unless they have a network type of `}<inlineCode parentName="p">{`none`}</inlineCode>{`).`}</p>
    <p>{`Bridged - Default network created by Docker to allow connection between Docker networks and the host machine.`}</p>
    <p>{`Host - Network config for the container as if it was the host machine with no port mapping required.`}</p>
    <p>{`None - No networking outside of the container for true isolation.`}</p>
    <h2><HashLink id="compose" to="/docker#compose" mdxType="HashLink">{`Docker Compose`}</HashLink></h2>
    <p><a parentName="p" {...{
        "href": "https://docs.docker.com/compose/"
      }}>{`Docker compose`}</a>{` is used for managing more than one container.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Compose is a tool for defining and running multi-container Docker applications. With Compose, you use a YAML file to configure your application’s services. Then, with a single command, you create and start all the services from your configuration.`}</p>
    </blockquote>
    <p>{`This hands-on `}<a parentName="p" {...{
        "href": "https://github.com/dockersamples/example-voting-app"
      }}>{`example voting application`}</a>{` uses Docker Compose.`}</p>
    <p>{`To get all the images defined in the `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker-compose pull

`}</code></pre>
    <p>{`Build everything in the `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker-compose build

`}</code></pre>
    <p>{`To start Compose and run the entire application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker-compose up

`}</code></pre>
    <p>{`To run in background:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker-compose up -d

`}</code></pre>
    <p>{`Some other useful commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
$ docker-compose ps

$ docker-compose logs -f

$ docker-compose down

`}</code></pre>
    <p>{`Note: The `}<inlineCode parentName="p">{`down`}</inlineCode>{` command also removes containers.`}</p>
    <p>{`Compose won’t help with things like load balancing. Orchestrators are good for things like this. Container orchestration is the automated process of managing or scheduling the work of individual containers for applications.`}</p>
    <p>{`Common Orchestrators`}</p>
    <ul>
      <li parentName="ul">{`Kubernetes`}</li>
      <li parentName="ul">{`Amazon ECS`}</li>
      <li parentName="ul">{`Docker Swarm`}</li>
      <li parentName="ul">{`Red Hat Openshift`}</li>
    </ul>
    <h2><HashLink id="dockerize" to="/docker#dockerize" mdxType="HashLink">{`Docker-izing an existing application`}</HashLink></h2>
    <p>{`The general strategy for taking an existing application and incorporating Docker looks something like this.`}</p>
    <ul>
      <li parentName="ul">{`Identify which base image you need`}</li>
      <li parentName="ul">{`Identify which tools/apps/runtimes to install in the container`}</li>
      <li parentName="ul">{`Identify what you’ll need to copy to the container (if anything)`}</li>
      <li parentName="ul">{`Get the app working`}</li>
      <li parentName="ul">{`Then worry about data persistence`}</li>
      <li parentName="ul">{`Identify opportunities for configuration (env variables, config file(s), scripts, etc)`}</li>
      <li parentName="ul">{`Optimize`}
        <ul parentName="li">
          <li parentName="ul">{`Make the image as small as possible`}</li>
          <li parentName="ul">{`Build time optimization (via cached layering, etc)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Add logging`}</li>
    </ul>
    <p>{`Here’s an example of an `}<a parentName="p" {...{
        "href": "https://github.com/Requarks/wiki"
      }}>{`existing application`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/mastermndio/contained-wikijs-docker"
      }}>{`it’s docker-ized implementation`}</a>{`.`}</p>
    <h2>{`References`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=IzwpfAcqvBc&list=PLleOCN2eBn8K5mTPzdXJN4xYpUaMoaq3A"
      }}>{`Contained - Docker Deep Dive`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://livecodestream.dev/post/2020-06-25-intro-to-docker-for-web-developers/"
      }}>{`Intro to Docker for Web Developers`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      